<template>
  <section>
    <add-edit-organization :is_edit="is_edit" v-on:showmodal="showModal" />
    <div v-if="!isLoaded" class="mt-2 h-96 bg-white rounded shadow vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <template v-if="isLoaded">
      <section
        class="grid grid-cols-1 gap-y-2 lg:grid-cols-2 lg:gap-x-2 lg:gap-y-0"
      >
        <info-grid-column
          :avatar="getAvatar"
          title="Basic Info"
          class="mt-2"
          grid-class="grid-cols-1"
          :data="getProfile"
          :edit-button="true"
          @edit="handleEditing()"
        >
          <template #footer>
            <div class="py-6" />
          </template>
        </info-grid-column>

        <!-- payment-section -->
        <section class="flex flex-col">
          <div class="overflow-hidden relative mt-2 content-section">
            <!-- circles -->
            <div
              class="absolute w-16 h-16 bg-green-400 rounded-full"
              style="right: -20px; top: -20px;"
            />
            <div
              class="absolute w-10 h-10 rounded-full bg-oYellow"
              style="right: -10px; top: -10px;"
            />
            <!-- /circles -->

            <!-- current-due -->
            <div
              class="flex gap-6 justify-between items-center py-5 bg-gray-50"
            >
              <div class="pl-6 w-full">
                <h2 class="text-3xl font-bold text-oGreen">
                  {{ profile.subscription_invoices.due_amount_currency }}
                  {{ profile.subscription_invoices.due_amount }}
                </h2>
                <p class="mt-1 text-sm text-oBlack">
                  Current Due
                </p>
              </div>

              <div class="w-40">
                <AppButton
                  :text="'Pay Now'"
                  rounded="full"
                  @click="$modal.show('MakePaymentModal')"
                />
              </div>
            </div>
            <!-- /current-due -->

            <!-- default-card -->
            <div
              class="grid grid-cols-3 gap-3 text-sm border-b py-2.5 items-center px-4"
            >
              <div>
                <p>
                  <i
                    class="text-lg"
                    :class="getCreditCardIcon(getDefCard.brand)"
                  />
                  **** {{ getDefCard.last_four_digits }}
                </p>
                <p class="text-xs text-oLightGray">
                  exp: {{ getDefCard.expiry }}
                </p>
              </div>

              <div class="text-center text-oBlack">
                {{ getDefCard.full_name }}
              </div>

              <p class="text-right text-oLightGray">
                Default
              </p>
            </div>
            <!-- /default-card -->
            <div class="py-4 px-2 border-b">
              <AppButton variant="secondary">
                <div class="flex gap-2 items-center">
                  <i class="fa fa-credit-card" />
                  <span class="text-xs font-medium">Add Card</span>
                </div>
              </AppButton>
            </div>

            <!-- latest-invoices -->
            <div class="flex flex-col gap-y-3 -mt-1 text-sm content-spacing">
              <div class="grid grid-cols-7 items-center px-1">
                <p class="col-span-2">ID</p>
                <p class="col-span-2">Due Date</p>
                <p class="col-span-2">Amount</p>
                <p class="">Invoice</p>
              </div>

              <template
                v-if="profile.subscription_invoices.latest_invoices.length > 0"
              >
                <div
                  v-for="inv in profile.subscription_invoices.latest_invoices"
                  class="grid grid-cols-7 items-center py-2 px-1 bg-gray-50 rounded-md shadow-sm"
                  :key="inv.id"
                >
                  <div class="col-span-2 text-blue-600">
                    #{{ $truncate(inv.id, -5) }}
                  </div>
                  <div class="col-span-2 text-oDark">
                    {{ $localTime(inv.due_date) }}
                  </div>

                  <div class="flex col-span-2 gap-2.5 items-center text-oDark">
                    {{ inv.currency.symbol }}{{ inv.amount }}
                    <XStatus
                      :text="inv.status_display_text"
                      :profile="`payment`"
                      :variant="'orange'"
                    />
                  </div>

                  <div class="text-oDark">
                    <AppButton height="30px" width="30px" variant="secondary">
                      <i class="fa fa-download" />
                    </AppButton>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="py-3">
                  No Invoices Here!
                </div>
              </template>
            </div>
            <!-- /latest-invoices -->

            <div class="text-xs text-center text-oBlack">
              <router-link to="#">
                View All Invoices
                <i class="fa fa-chevron-right" />
              </router-link>
            </div>
          </div>

          <div class="-mb-2 h-72 content-spacing content-section">
            <div class="flex justify-between items-center">
              <div class="font-bold text-oBlack text-19px">
                Subscription Plan
              </div>
              <div>
                <div
                  class="flex justify-center items-center cursor-pointer edit-button"
                  @click="handleEditing(2)"
                >
                  <svg
                    class="mt-px w-4 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    ></path>
                  </svg>
                  <span class="ml-2 font-semibold text-oBlack"> Edit</span>
                </div>
              </div>
            </div>

            <div
              class="grid place-items-center mt-12"
              :class="getSubscriptionPlanClass"
            >
              <img
                v-if="getSubscriptionPlan.vehicles.includes('SCOOTER')"
                src="@/assets/img/vehicles/scooter-1x.png"
              />
              <img
                v-if="getSubscriptionPlan.vehicles.includes('BIKE')"
                src="@/assets/img/vehicles/bicycle-1x.png"
              />
              <img
                v-if="getSubscriptionPlan.vehicles.includes('EBIKE')"
                src="@/assets/img/vehicles/ebike-1x.png"
              />
            </div>

            <div
              class="mt-6 font-bold text-center capitalize text-19px text-oGreen"
            >
              {{ symbol }} {{ getSubscriptionPlan.price }} /
              {{ getSubscriptionPlan.recurrence }}
            </div>

            <div
              class="my-1 font-semibold text-center capitalize text-oLightGray text-14px"
            >
              {{ getSubscriptionPlan.name }}
            </div>
          </div>
        </section>
        <!-- /payment-section -->
      </section>

      <section
        class="grid grid-cols-1 gap-y-2 mt-2 lg:grid-cols-2 lg:gap-x-2 lg:gap-y-0"
      >
        <info-grid-column
          title="Settings"
          class="mt-2"
          grid-class=""
          :data="getSettings"
          :edit-button="true"
          @edit="handleEditing(3)"
        />

        <info-grid
          title="Domain & Port"
          class="mt-2 -mb-2"
          :edit-button="true"
          @edit="handleEditing(3)"
        >
          <template v-slot:subheader>
            <div class="grid grid-cols-3 py-4 border-b border-oLighterGray">
              <div class="font-medium text-center text-oBlack text-14px">
                Vehicle
              </div>
              <div class="font-medium text-center text-oBlack text-14px">
                Domain
              </div>
              <div class="font-medium text-center text-oBlack text-14px">
                Port
              </div>
            </div>
            <div
              class="grid grid-cols-3 py-4 border-b border-oLighterGray"
              v-for="(domain, domainIndex) in domains"
              :key="domainIndex"
            >
              <div class="font-medium text-center text-oGray text-14px">
                {{ getVehicleCategoryByFlag(domain.vehicle_category) }}
              </div>
              <div class="font-medium text-center text-oGray text-14px">
                {{ domain.domain || '--' }}
              </div>
              <div class="font-medium text-center text-oGray text-14px">
                {{ domain.port || '--' }}
              </div>
            </div>
          </template>
        </info-grid>
      </section>

      <section
        class="grid grid-cols-1 gap-y-2 mt-2 lg:grid-cols-2 lg:gap-x-2 lg:gap-y-0"
      >
        <info-grid-column
          title="Owner Details"
          class="mt-2"
          grid-class=""
          :data="getOwner"
          :edit-button="true"
          @edit="handleEditing(4)"
        />

        <div class="content-section content-spacing">
          <!-- start: title-section -->
          <div class="flex justify-between items-center">
            <div class="font-bold text-oBlack text-19px">Web Links</div>
            <div>
              <div
                class="flex justify-center items-center cursor-pointer edit-button"
                @click="handleEditing(3)"
              >
                <svg
                  class="mt-px w-4 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path>
                </svg>
                <span class="ml-2 font-semibold text-oBlack"> Edit</span>
              </div>
            </div>
          </div>
          <!-- end: title-section -->
          <section class="grid grid-cols-1 lg:grid-cols-2">
            <div class="flex items-center my-5">
              <div class="web-link-icon">
                <i class="fab fa-google-play"></i>
              </div>
              <div class="ml-3 w-full">
                <div class="font-semibold text-14px bont-oBlack">
                  Google Playstore
                </div>
                <div
                  class="-mt-1 font-medium text-14px"
                  :class="getWebLinksClass(getWebLinks.playstore)"
                >
                  {{ getWebLinks.playstore }}
                </div>
              </div>
            </div>

            <div class="flex items-center my-5">
              <div class="web-link-icon">
                <i class="fab fa-apple"></i>
              </div>
              <div class="ml-3 w-full">
                <div class="font-semibold text-14px bont-oBlack">
                  Apple Store
                </div>
                <div
                  class="-mt-1 font-medium text-14px"
                  :class="getWebLinksClass(getWebLinks.applestore)"
                >
                  {{ getWebLinks.applestore }}
                </div>
              </div>
            </div>
          </section>

          <section class="grid grid-cols-1 lg:grid-cols-2">
            <div class="flex items-center my-8">
              <div class="web-link-icon">
                <i class="fas fa-globe-asia"></i>
              </div>
              <div class="ml-3 w-full">
                <div class="font-semibold text-14px bont-oBlack">Website</div>
                <div
                  class="-mt-1 font-medium text-14px"
                  :class="getWebLinksClass(getWebLinks.website)"
                >
                  {{ getWebLinks.website }}
                </div>
              </div>
            </div>

            <div class="flex items-center my-8">
              <div class="web-link-icon">
                <i class="fas fa-envelope"></i>
              </div>
              <div class="ml-3 w-full">
                <div class="font-semibold text-14px bont-oBlack">Email</div>
                <div
                  class="-mt-1 font-medium text-14px"
                  :class="getWebLinksClass(getWebLinks.email)"
                >
                  {{ getWebLinks.email }}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="py-3" />
    </template>

    <MakePaymentModal
      @payment:triggered="fetchData"
      @payment:failed="fetchData"
    />
  </section>
</template>

<script>
import xMan from '@/utils/xMan'
import { getCreditCardIcon } from '@/utils'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { OrganizationResource } from '@/resources/OrganizationResource'

// import DisplayAvatar from "@/components/ui/DisplayAvatar.vue";
import InfoGrid from '@/components/layout/InfoGrid'
import InfoGridColumn from '@/components/layout/InfoGridColumn.vue'
import XStatus from '@/components/badge/XStatus.vue'
// import ContentSection from '@/components/layout/ContentSection.vue'
import { EventBus } from '@/utils/EventBus'
import AddEditOrganization from '@/views/organization/AddEditOrganization'
import { mapGetters } from 'vuex'
import MakePaymentModal from '@/composites/credit-card/MakePaymentModal.vue'
// import { useEndpoints } from '@/composables'

// eslint-disable-next-line
// import { getVehicleCategoryByFlag } from "@/utils/Helpers.js";

export default {
  components: {
    InfoGrid,
    InfoGridColumn,
    // ContentSection,
    AddEditOrganization,
    XStatus,
    MakePaymentModal,
  },
  data() {
    return {
      isLoaded: false,
      fallbackText: '--',
      profile: OrganizationResource,
      is_edit: true,
      symbol: '$',
      domains: [],
      defaultCard: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    id() {
      return this.$route.params.id
    },
    getAvatar() {
      return this.profile.avatar
    },
    getWebLinks() {
      return {
        playstore:
          this.profile && this.profile.play_store_link !== ''
            ? this.profile.play_store_link
            : this.fallbackText,

        applestore:
          this.profile && this.profile.apple_store_link !== ''
            ? this.profile.apple_store_link
            : this.fallbackText,
        website:
          this.profile && this.profile.website_link !== ''
            ? this.profile.website_link
            : this.fallbackText,
        email:
          this.profile && this.profile.email_address !== ''
            ? this.profile.email_address
            : this.fallbackText,
      }
    },
    getSubscriptionPlan() {
      let plan = this.profile.subscription

      let getRecurrence = function() {
        let remove = 'VEHICLE/'
        if (!plan) return null
        let type = plan.billing_type.slice(remove.length).toLowerCase()
        return type[0].toString().toUpperCase() + type.slice(1, type.length)
      }

      return {
        name: plan ? plan.plan_name : this.fallbackText,
        price: plan ? plan.price : this.fallbackText,
        recurrence: plan ? getRecurrence() : this.fallbackText,
        vehicles:
          this.profile && this.profile.vehicle_type
            ? this.profile.vehicle_type
            : [],
        totalVehicles:
          this.profile && this.profile.vehicle_type
            ? this.profile.vehicle_type.length
            : 0,
      }
    },
    getSubscriptionPlanClass() {
      return 'grid-cols-' + this.getSubscriptionPlan.totalVehicles
    },
    getProfile() {
      let data = []
      data.push({
        text: 'Name',
        value: this.profile ? this.profile.name : this.fallbackText,
      })
      data.push({
        text: 'Alias',
        value: this.profile ? this.profile.alias : this.fallbackText,
      })
      data.push({
        text: 'Country',
        value:
          this.profile && this.profile.country
            ? this.profile.country.name
            : this.fallbackText,
      })
      data.push({
        text: 'City',
        value:
          this.profile && this.profile.city
            ? this.profile.city.name
            : this.fallbackText,
      })
      data.push({
        text: 'Type',
        value: this.profile
          ? this.profile.organization_category
          : this.fallbackText,
      })
      data.push({
        text: 'Default Fleet',
        value:
          this.profile && this.profile.default_fleet
            ? this.profile.default_fleet.name
            : this.fallbackText,
      })
      data.push({
        text: 'Phone Number',
        value: this.profile ? this.profile.phone_number : this.fallbackText,
      })
      data.push({
        text: 'Postal/Zip',
        value: this.profile ? this.profile.zip_code : this.fallbackText,
      })
      data.push({
        text: 'Address',
        value: this.profile ? this.profile.address : this.fallbackText,
      })
      data.push({
        text: 'Organization ID',
        value: this.profile ? this.profile.id : this.fallbackText,
      })
      return data
    },
    getSettings() {
      let data = []
      let payment = this.profile.default_payment_gateway_config
      data.push({
        text: 'Gateway Name',
        value: payment ? payment.payment_gateway : this.fallbackText,
      })
      data.push({
        text: 'Status',
        value: payment
          ? payment.is_enabled === true
            ? 'Active'
            : 'Inactive'
          : this.fallbackText,
      })
      data.push({
        text: 'Client ID',
        value: payment
          ? new xMan(payment.client_id).asteriskify(5, 15)
          : this.fallbackText,
      })
      data.push({
        text: 'Secret Key',
        value: payment
          ? new xMan(payment.secret_key).asteriskify(5, 15)
          : this.fallbackText,
      })
      data.push({
        text: 'App Key',
        value: payment
          ? new xMan(payment.app_key).asteriskify(5, 15)
          : this.fallbackText,
      })
      data.push({
        text: 'Extra Key',
        value: payment
          ? new xMan(payment.extra_key).asteriskify(5, 15)
          : this.fallbackText,
      })
      return data
    },
    getOwner() {
      let data = []
      let owner = this.profile.owner_user
      data.push({
        text: 'Name',
        value: owner ? owner.full_name : this.fallbackText,
      })
      data.push({
        text: 'Username',
        value: owner ? owner.username : this.fallbackText,
      })
      data.push({
        text: 'Email',
        value: owner ? owner.email : this.fallbackText,
      })
      data.push({
        text: 'Phone Number',
        value: owner ? owner.phone_number : this.fallbackText,
      })
      data.push({
        text: 'Owner ID',
        value: owner ? owner.id : this.fallbackText,
      })
      return data
    },
    getDomain() {
      let data = []
      let owner = this.profile.owner_user
      data.push({
        text: 'Name',
        value: owner ? owner.full_name : this.fallbackText,
      })
      data.push({
        text: 'Username',
        value: owner ? owner.username : this.fallbackText,
      })
      data.push({
        text: 'Email',
        value: owner ? owner.email : this.fallbackText,
      })
      data.push({
        text: 'Phone Number',
        value: owner ? owner.phone_number : this.fallbackText,
      })
      data.push({
        text: 'Owner ID',
        value: owner ? owner.id : this.fallbackText,
      })
      return data
    },
    getDefCard() {
      const def = this.profile?.default_subscription_payment_method
      if (def) {
        def.expiry = this.$dayjs(def.expiry).format('MM/YY')
        def.full_name = def?.user?.full_name ?? '--'
        def.last_four_digits = def.number.slice(-4)
      }

      return (
        def ?? {
          brand: 'N/A',
          created_at: 'N/A',
          expiry: '--/--',
          id: null,
          number: '**** **** **** ----',
          full_name: 'N/A',
          last_four_digits: 'N/A',
        }
      )
    },
  },
  async created() {
    this.fetchData()
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
  },
  methods: {
    fetchData() {
      this.$http
        .get(OrganizationConfig.api.single(this.id))
        .then((res) => {
          console.log('view-profile', res.data)
          this.profile = { ...res.data }
          this.isLoaded = true
        })
        .catch((err) => {
          console.log('view-profil-err', err)
        })
      // domains
      this.$http
        .get(OrganizationConfig.api.vehicleDomain.index(this.id))
        .then((res) => {
          console.log('vehicle domain', res.data)
          this.domains = res.data.data
        })
        .catch((err) => {
          console.log('vehicle domain err', err)
        })
    },

    getCreditCardIcon,

    showModal() {
      dispatchEvent(new Event(OrganizationConfig.events.sorToggle))
      return
    },
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/organizations')
    },
    getWebLinksClass(link) {
      return link === '--' || link === '' ? 'text-oGray' : 'text-oPurple'
    },
    handleEditing(step = -1) {
      var item = {
        id: this.id,
        edit: true,
      }
      this.$store.dispatch('organization/saveStep', {
        step: step == -1 ? 1 : step,
      })
      EventBus.$emit(OrganizationConfig.events.editingData, item)
      dispatchEvent(new Event(OrganizationConfig.events.sorToggle))
      this.is_edit = true
    },
    getVehicleCategoryByFlag(flag) {
      let aliases = {
        P: 'Bike',
        S: 'Scooter',
        E: 'EBike',
      }
      return flag in aliases ? aliases[flag] : '--'
    },
  },
}
</script>

<style lang="scss">
.edit-button {
  width: 96px;
  height: 42px;
  // margin: 0 0 17px 19px;
  padding: 4px 23px 5px 12px;
  border-radius: 30px;
  background-color: #f3f3f3;
}

.web-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 42px;
  background: rgba(243, 244, 246, 1);
  border: 1px solid rgba(203, 213, 224, 1);
  margin-left: 0.25rem;
  border-radius: 0.25rem;
}
</style>
